<template>
  <div style="width:100vw;height:98vh;overflow-y:hidden">
    <div class="is-flex is-flex-direction-column pt-6 gradBkg" >
      
      <!-- Header -->
      <div class="panel-heading is-blue is-sharp">
        <button
          class="button is-link is-sharp is-pulled-right"
          @click="back"
        >
          <span><font-awesome-icon icon="times" /></span>
        </button>

        <button
          v-if="loggedIn"
          class="button is-sharp is-pulled-right"
          @click="ShowEditRecord=true"
        >Quick Edit</button>

        <p class="ml-4 is-header has-text-white has-text-left">
          {{ collection.title }}
        </p>
        <p class="ml-4 is-text has-text-white has-text-left is-size-6-desktop is-size-7-mobile">
          {{ locationAddress }}
        </p>
      </div>

      <!-- Contents that scroll -->
      <div ref="scroll" class="is-flex" style="width:100%;overflow-y:auto; overflow-x:hidden">
        <div class="columns is-centered" style="width:100%">
          <div class="column ml-3 mt-3 is-half-desktop is-full-mobile" style="">
            <!-- Image -->
            <div
              class="
                is-charcoal
                is-flex
                is-align-items-center
                is-justify-content-center
                is-inline-block
              "
              style="width: 100%; height: 50%"
            >
              <font-awesome-icon
                icon="image"
                style="width: 50%; height: auto"
                v-show="currentRecordImage == ''"
              />
              <img
                :src="currentRecordImage"
                style="
                  width: 100%;
                  height: 100%;
                  object-fit: contain;
                  aspect-ratio: 1;
                "
                v-show="currentRecordImage != ''"
              />
            </div>
            <p class="mt-2 is-title has-text-left is-size-5">
              {{ currentRecord != null ? currentRecord.title : "" }}
            </p>
            <p class="is-text has-text-left is-size-6">
              {{ currentRecord != null ? currentRecord.description : "" }}
            </p>

            <!-- Text -->
            <div class="container my-5 ">
              <div class="columns">
                <div class="column is-vcentered">
                  <p class="is-title is-size-6-desktop is-size-7-mobile">
                    OBJECT ID
                  </p>
                  <p class="is-text is-6">
                    {{ currentRecord != null ? currentRecord.object_id : "" }}
                  </p>
                </div>
                <div class="column is-vcentered">
                  <p class="is-title is-size-6-desktop is-size-7-mobile">DATE</p>
                  <p class="is-text is-6">
                    {{ currentRecord != null ? currentRecord.date : "" }}
                  </p>
                </div>
                <div class="column is-vcentered">
                  <p class="is-title is-size-6-desktop is-size-7-mobile">
                    GOOGLE MAPS
                  </p>
                  <!-- PLACE LINK TO ADDRESS OR LAT LONG HERE -->
                  <button
                    type="button"
                    @click="openGoogleMaps"
                    class="is-transparent"
                    style="width: 50px; height: auto; cursor: pointer"
                  >
                    <img :src="require('../../assets/location_marker.png')" />
                  </button>
                </div>
              </div>

              <div class="columns has-text-left is-multiline">
                <div class="column is-12">
                  <p class="is-title is-size-6-desktop is-size-7-mobile">
                    Credit:
                  </p>
                  <p class="is-text is-size-6-desktop is-size-7-mobile">
                    {{ currentRecord != null ? currentRecord.credit : "" }}
                  </p>
                </div>
                <div class="column is-12">
                  <p class="is-title is-size-6-desktop is-size-7-mobile">
                    Photographer:
                  </p>
                  <p class="is-text is-size-6-desktop is-size-7-mobile"
                  >
                    {{ currentRecord != null ? currentRecord.photographer : "" }}
                  </p>
                </div>
                <div class="column is-12">
                  <p
                    class="
                      is-title is-size-6-desktop is-size-7-mobile
                      has-blue-text
                    "
                  >
                    Purchase Information:
                  </p>
                  <a :href="purchase_url" target="_blank" class="is-text is-size-6-desktop is-size-7-mobile">
                  {{
                      currentRecord != null ? currentRecord.image_description : purchase_url
                    }}
                  
                  </a>
                  <!-- <p class="is-text is-size-6-desktop is-size-7-mobile"
                  >
                    {{
                      currentRecord != null ? currentRecord.image_description : ""
                    }}
                  </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Next and prev buttons -->
      <div
        v-show="records.length > 1"
        class="is-blue"
        style="width:100%;bottom:0"
      >
        <div
          class="
            is-flex is-flex-direction-row
            are-small-mobile are-medium-desktop
            is-justify-content-space-evenly
            my-2
          "
        >
          <div
            class="button is-link is-sharp is-text"
            style="text-decoration: none"
            :class="{ 'is-static': active === 0 }"
            @click="prevImage"
          >
            Prev
          </div>
          <p class="is-text has-text-white my-auto">{{ active + 1 }} / {{ records.length }}</p>
          <div
            class="button is-link is-sharp is-text"
            style="text-decoration: none"
            :class="{ 'is-static': active === records.length - 1 }"
            @click="nextImage"
          >
            Next
          </div>
        </div>
      </div>
      
    </div>

    <div v-if="ShowEditRecord" class="mt-6 is-overlay is-charcoal-overlay">
            <AddRecordForm
              :isEditForm="true"
              :collection_id="collection.id"
              :location_id="location.id"
              :recordToEdit="records[active]"
              @close="ShowEditRecord = false"
            />
          </div>
  </div>
</template>

<script>
import AddRecordForm from "../records/AddRecordForm.vue"
export default {
  components:{AddRecordForm},
  data() {
    return {
      active: 0,
      collection: {},
      location: {},
      records: [],
      ShowEditRecord:false,
      purchase_url: process.env.VUE_APP_PURCHASE_URL
    };
  },

  async mounted() {
    try{
      this.$store.commit("AWAIT_RESPONSE", true);
      await this.setActiveCollection();
      await this.setActiveLocation();
      await this.setLocationRecords();
      this.$store.commit("AWAIT_RESPONSE", false);
    }
    catch(error){
      this.$store.dispatch("showErrorMessage", error);
    }
  },
  computed: {
    currentRecord() {
      return this.records[this.active];
    },
    currentRecordImage() {
      if (this.currentRecord != null) return this.records[this.active].image;
      else return "";
    },
    locationAddress() {
      if (this.location == null) return "";
      return this.location.address != null && this.location.address != ""
        ? this.location.address
        : this.location.latitude + ", " + this.location.longitude;
    },
    loggedIn() {
      return this.$store.getters.isAuthenticated;
    },
    // activeCollection() {
    //   // const col = this.$store.state.collections.activeCollection;
    //   // if (!col.id) {
    //   //   this.setActiveCollection();
    //   // }
    //   // return col;
    //   return this.$store.state.collections.activeCollection;
    // },
    // activeLocation() {
    //   // const loc = this.$store.state.locations.active;
    //   // if (!loc.id) {
    //   //   console.log("no active loc found");
    //   //   this.setActiveLocation();
    //   // }
    //   // return loc;
    //   return this.$store.state.locations.active;
    // },
    // locationRecords() {
    //    return this.$store.state.records.all.items;
    // },
  },
  methods: {
    async setActiveCollection() {
      this.collection = this.$store.state.collections.activeCollection;
      const collectionId = this.$route.params.id;
      if (!this.collection.id || this.collection.id != collectionId) {
        this.collection = await this.$store.dispatch(
          "collections/setCollection",
          collectionId
        );
      }

      //console.log(this.collection);
    },
    async setActiveLocation() {
      this.location = this.$store.state.locations.active;
      const locationId = this.$route.params.location_id;
      if (!this.location.id || this.location.id != locationId) {
        const collectionId = this.$route.params.id;

        const path =
          "/collections/" + collectionId + "/locations/" + locationId;
        this.location = await this.$store.dispatch(
          "locations/getLocationFromRoute",
          path
        );
      }

      //console.log(this.location);
    },
    async setLocationRecords() {
      if (this.location.id) {
        this.active = 0;
        //get all records
        this.records = await this.$store.dispatch("records/getAll",this.location);
        if(this.records.length > 0) this.records.sort((a, b) => (a.date > b.date ? 1 : -1));

        if(this.$route.params.record_id != ""){
          //use param to set index of active record
          const result = this.records.filter(r => r.id == this.$route.params.record_id);
          if(result.length > 0){
            this.active = this.records.indexOf(result[0]);
          }
        }
      }
    },
    nextImage() {
      if (this.active < this.records.length - 1) {
        this.active++;
        this.$refs.scroll.scrollTop = 0;
      }
    },
    prevImage() {
      if (this.active > 0) {
        this.active--;
        this.$refs.scroll.scrollTop = 0;
      }
    },
    back() {
      this.$router.back();
    },
    openGoogleMaps() {
      const urlSuffix = this.location.address
        ? this.location.address
        : this.location.latitude + "," + this.location.longitude;
      window.open(
        "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
        "_blank"
      );
    },
  },
};
</script>

<style scoped>
.gradBkg{
  width:100%;
  height:100%;
  max-width:100%;
  max-height:100%;
  background-image: linear-gradient(white, #c4dff4);
}
</style>